var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"alerts","fluid":"","tag":"section"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.carga),callback:function ($$v) {_vm.carga=$$v},expression:"carga"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',{staticClass:"text-h4 font-weight-light white--text"},[_vm._v(" "+_vm._s(_vm.textoMensaje)+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-navigation-drawer',{attrs:{"absolute":"","width":"100%","permanent":""}},[_c('div',{staticClass:"top-main d-flex flex-column align-center justify-center"},[_c('v-img',{attrs:{"src":"assets/logo_la_n.png","max-width":"250"},on:{"click":function($event){_vm.$router.push(
            { path: '/data' },
            function () {
              _vm.$emit('reload')
            }
          )}}})],1),_c('div',[_c('v-dialog',{attrs:{"width":"100%","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogConsulta),callback:function ($$v) {_vm.dialogConsulta=$$v},expression:"dialogConsulta"}},[_c('base-material-card',{attrs:{"icon":"mdi-seal","color":"primary","title":_vm.tituloDialog}},[_c('br'),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsFiltro,"hide-selected":"","autofocus":"","item-text":"nombre","item-value":"cod","clearable":"","label":"Busque el tipo de filtro","placeholder":"Escriba o seleccione el filtro"},on:{"input":function($event){return _vm.getFiltroSeleccionado()}},model:{value:(_vm.filtroSeleccionado),callback:function ($$v) {_vm.filtroSeleccionado=$$v},expression:"filtroSeleccionado"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"tile":"","block":"","color":"primary"},on:{"click":function($event){return _vm.leerData()}}},[_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-refresh ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"tile":"","block":"","color":"green"},on:{"click":function($event){return _vm.generarExcel()}}},[_c('v-icon',[_vm._v(" mdi-file-excel ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{staticClass:"ma-0",staticStyle:{"max-width":"750px"},attrs:{"append-icon":"mdi-magnify","label":"Buscar...","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataConsulta,"search":_vm.search,"dense":"","light":"","sort-by":['cantidad'],"sort-desc":true,"fixed-header":"","height":"300px","loading":_vm.carga,"loading-text":"Cargando, por favor espere","footer-props":{
                    'items-per-page-options': [10, 25, 50, -1],
                    'items-per-page-text': 'Mostrando:',
                    'items-per-page-all-text': 'Todos'
                  },"header-props":{
                    'sortByText': 'Ordenar por'
                  },"no-data-text":"No hay datos registrados","no-results-text":"No se encontraron resultados"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){_vm.dialogConsulta = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1),(!_vm.carga)?_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('base-material-card',{attrs:{"color":"primary","width":"550","max-width":"85%","icon":"mdi-file-document-arrow-right","title":"carga del org. jurisd."}},[_c('div',{staticClass:"mb-n4 mt-2 text-caption font-weight-light"},[_vm._v(" Consulte sobre la carga de un órgano jurisdiccional por mes, con información como expediente, estado, detalle, tipo de acto, motivo de ingreso y materia "),_c('br'),_vm._v("Para realizar la búsqueda seleccione el órgano jurisdiccional y mes a consultar. ")]),_c('v-card',{staticClass:"d-flex flex-column align-center justify-center"},[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsJuzgado,"hide-no-data":"","hide-selected":"","item-text":"x_nom_instancia","item-value":"c_instancia","label":"Órgano jurisdiccional","placeholder":"Escriba o seleccione el tipo órgano jurisdiccional","prepend-icon":"mdi-database-search","chips":"","small-chips":"","return-object":""},model:{value:(_vm.juzgadoSeleccionado),callback:function ($$v) {_vm.juzgadoSeleccionado=$$v},expression:"juzgadoSeleccionado"}})],1),_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-dialog',{ref:"dialogFechaFin",attrs:{"return-value":_vm.mesConsulta,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.mesConsulta=$event},"update:return-value":function($event){_vm.mesConsulta=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Fechas a consultar","prepend-icon":"mdi-calendar","readonly":"","rules":_vm.regla},model:{value:(_vm.mesConsulta),callback:function ($$v) {_vm.mesConsulta=$$v},expression:"mesConsulta"}},'v-text-field',attrs,false),on))]}}],null,false,418410098),model:{value:(_vm.modalFechaFin),callback:function ($$v) {_vm.modalFechaFin=$$v},expression:"modalFechaFin"}},[_c('v-date-picker',{attrs:{"scrollable":"","rules":_vm.regla,"locale":"es","type":"month","color":"primary"},model:{value:(_vm.mesConsulta),callback:function ($$v) {_vm.mesConsulta=$$v},expression:"mesConsulta"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFechaFin = false}}},[_vm._v(" CANCELAR ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.dialogFechaFin.save(_vm.mesConsulta)}}},[_vm._v(" GUARDAR ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.consultarData()}}},[_vm._v(" consultar ")])],1)],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-body-2 white--text text-center",on:{"click":function($event){return _vm.$router.push('/cerrar_sesion')}}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" © CSJLA ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }