<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
  >
    <v-form
      ref="form"
      lazy-validation
    >
      <v-dialog
        v-model="carga"
        persistent
        width="350"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text class="text-h4 font-weight-light white--text">
            {{ textoMensaje }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-navigation-drawer
        absolute
        width="100%"
        permanent
      >
        <div
          class="top-main d-flex flex-column align-center justify-center"
        >
          <v-img
            src="assets/logo_la_n.png"
            max-width="250"
            @click="$router.push(
              { path: '/data' },
              () => {
                $emit('reload')
              },
            )"
          />
        </div>
        <div>
          <v-dialog
            v-model="dialogConsulta"
            width="100%"
            persistent
            transition="dialog-bottom-transition"
          >
            <base-material-card
              icon="mdi-seal"
              color="primary"
              :title="tituloDialog"
            >
              <br>
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="filtroSeleccionado"
                    :items="itemsFiltro"
                    hide-selected
                    autofocus
                    item-text="nombre"
                    item-value="cod"
                    clearable
                    label="Busque el tipo de filtro"
                    placeholder="Escriba o seleccione el filtro"
                    @input="getFiltroSeleccionado()"
                  />
                </v-col>
                <!-- <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    tile
                    block
                    color="primary"
                    @click="agruparSecretario()"
                  >
                    secretario x juzgado
                    <v-icon right>
                      mdi-account-tie
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    tile
                    block
                    color="primary"
                    @click="agruparTipo()"
                  >
                    juzgado x tipo doc
                    <v-icon right>
                      mdi-file
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    tile
                    block
                    color="primary"
                    @click="agruparJuzgado()"
                  >
                    juzgado
                    <v-icon right>
                      mdi-domain
                    </v-icon>
                  </v-btn>
                </v-col> -->
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    tile
                    block
                    color="primary"
                    @click="leerData()"
                  >
                    <v-icon right>
                      mdi-refresh
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="1"
                >
                  <v-btn
                    tile
                    block
                    color="green"
                    @click="generarExcel()"
                  >
                    <v-icon>
                      mdi-file-excel
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-spacer />
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar..."
                    hide-details
                    class="ma-0"
                    style="max-width: 750px;"
                  />
                </v-col>
              </v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="dataConsulta"
                    :search="search"
                    dense
                    light
                    :sort-by="['cantidad']"
                    :sort-desc="true"
                    class="elevation-1"
                    fixed-header
                    height="300px"
                    :loading="carga"
                    loading-text="Cargando, por favor espere"
                    :footer-props="{
                      'items-per-page-options': [10, 25, 50, -1],
                      'items-per-page-text': 'Mostrando:',
                      'items-per-page-all-text': 'Todos'
                    }"
                    :header-props="{
                      'sortByText': 'Ordenar por'
                    }"
                    no-data-text="No hay datos registrados"
                    no-results-text="No se encontraron resultados"
                  />
                </v-card-text>
              </v-col>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="primary"
                  block
                  @click="dialogConsulta = false"
                >
                  Cerrar
                </v-btn>
              </v-card-actions>
            </base-material-card>
          </v-dialog>
        </div>
        <v-row
          v-if="!carga"
          justify="center"
          no-gutters
        >
          <base-material-card
            color="primary"
            width="550"
            max-width="85%"
            icon="mdi-file-document-arrow-right"
            title="carga del org. jurisd."
          >
            <div class="mb-n4 mt-2 text-caption font-weight-light">
              Consulte sobre la carga de un órgano jurisdiccional por mes, con información como expediente, estado, detalle, tipo de acto, motivo de ingreso y materia
              <br>Para realizar la búsqueda seleccione el órgano jurisdiccional y mes a consultar.
            </div>
            <v-card
              class="d-flex flex-column align-center justify-center"
            >
              <v-col
                cols="12"
                md="11"
              >
                <v-autocomplete
                  v-model="juzgadoSeleccionado"
                  :items="itemsJuzgado"
                  hide-no-data
                  hide-selected
                  item-text="x_nom_instancia"
                  item-value="c_instancia"
                  label="Órgano jurisdiccional"
                  placeholder="Escriba o seleccione el tipo órgano jurisdiccional"
                  prepend-icon="mdi-database-search"
                  chips
                  small-chips
                  return-object
                />
              </v-col>
              <v-col
                cols="12"
                md="11"
              >
                <v-dialog
                  ref="dialogFechaFin"
                  v-model="modalFechaFin"
                  :return-value.sync="mesConsulta"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="mesConsulta"
                      label="Fechas a consultar"
                      prepend-icon="mdi-calendar"
                      readonly
                      :rules="regla"
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="mesConsulta"
                    scrollable
                    :rules="regla"
                    locale="es"
                    type="month"
                    color="primary"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="modalFechaFin = false"
                    >
                      CANCELAR
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialogFechaFin.save(mesConsulta)"
                    >
                      GUARDAR
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-btn
                  color="primary"
                  block
                  @click="consultarData()"
                >
                  consultar
                </v-btn>
              </v-col>
            </v-card>
          </base-material-card>
        </v-row>
        <div
          class="text-body-2 white--text text-center"
          @click="$router.push('/cerrar_sesion')"
        >
          {{ new Date().getFullYear() }} &copy; CSJLA
        </div>
      </v-navigation-drawer>
    </v-form>
  </v-container>
</template>

<script>
  import axios from 'axios'
  const lodash = require('lodash')
  export default {
    name: 'Menu',
    data: () => ({
      carga: true,
      model: null,
      search: '',
      itemsFiltro: [{ nombre: 'Estado', cod: 1 }],
      items: [],
      itemsJuzgado: [],
      juzgadoSeleccionado: '',
      regla: [(v) => !!v || 'Campo requerido'],
      mesConsulta: '',
      fechaInicio: '',
      fechaFin: '',
      modalFechaInicio: false,
      modalFechaFin: false,
      dialogConsulta: false,
      dataConsulta: [],
      expanded: [],
      listaSecretarios: [],
      dataAgrupada: [],
      dataInicial: [],
      textoMensaje: 'CARGANDO PÁGINA',
      headers: [{
                  text: 'Juzgado',
                  value: 'x_nom_instancia',
                  width: 230,
                },
                {
                  text: 'Expediente',
                  value: 'x_formato',
                  width: 250,
                },
                {
                  text: 'Estado',
                  value: 'var_des',
                  width: 230,
                },
                {
                  text: 'Detalle',
                  value: 'var_des_det',
                  width: 280,
                },
                {
                  text: 'Acto',
                  value: 'x_desc_acto_procesal',
                  width: 350,
                },
                {
                  text: 'Motivo de ingreso',
                  value: 'x_desc_motivo_ingreso',
                  width: 280,
                },
                {
                  text: 'Proceso',
                  value: 'x_desc_proceso',
                  width: 320,
                },
                {
                  text: 'Materia',
                  value: 'x_desc_materia',
                  width: 320,
                },
                {
                  text: 'Delito',
                  value: 'delito_articulo',
                  width: 320,
                }],
      tipoData: 1,
      tituloDialog: '',
      filtroSeleccionado: '',
      switchSecretario: false,
      secretarioSeleccionado: '',
    }),
    created () {
      this.dataUsuario = JSON.parse(localStorage.getItem('usuarioDataCSJLA'))
      if (!this.dataUsuario) {
        localStorage.removeItem('usuarioDataCSJLA')
        this.$router.push('/')
      }
      this.$store.commit('SET_VALOR', true)
      this.itemsJuzgado = []
      setTimeout(() => { this.carga = false }, 1500)
      this.leerJuzgados()
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      getFiltroSeleccionado () {
        this.tipoData = 2
        if (this.filtroSeleccionado === 1) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.var_des}"`), 'estado')
        } else if (this.filtroSeleccionado === 2) {
          this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.x_nom_instancia}"`), 'juzgado')
        }
      },
      limpiarCampos () {
        this.juzgadoSeleccionado = ''
        this.secretarioSeleccionado = ''
      },
      leerData () {
        this.headers = [{
                          text: 'Juzgado',
                          value: 'x_nom_instancia',
                          width: 230,
                        },
                        {
                          text: 'Expediente',
                          value: 'x_formato',
                          width: 250,
                        },
                        {
                          text: 'Estado',
                          value: 'var_des',
                          width: 230,
                        },
                        {
                          text: 'Detalle',
                          value: 'var_des_det',
                          width: 280,
                        },
                        {
                          text: 'Acto',
                          value: 'x_desc_acto_procesal',
                          width: 350,
                        },
                        {
                          text: 'Motivo de ingreso',
                          value: 'x_desc_motivo_ingreso',
                          width: 280,
                        },
                        {
                          text: 'Proceso',
                          value: 'x_desc_proceso',
                          width: 320,
                        },
                        {
                          text: 'Materia',
                          value: 'x_desc_materia',
                          width: 320,
                        }, {
                          text: 'Delito',
                          value: 'delito_articulo',
                          width: 320,
                        }]
        this.carga = false
        this.dataConsulta = this.dataInicial
        this.dialogConsulta = true
        this.tipoData = 1
        this.filtroSeleccionado = ''
      },
      generarExcel () {
        if (this.tipoData === 1) {
          this.itemsXls = this.dataConsulta.map(obj => {
            const rObj = {}
            rObj.Juzgado = obj.x_nom_instancia
            rObj.Expediente = obj.x_formato
            rObj.Estado = obj.var_des
            rObj.Detalle = obj.var_des_det
            rObj.Acto = obj.x_desc_acto_procesal
            rObj['Motivo de ingreso'] = obj.x_desc_motivo_ingreso
            rObj.Proceso = obj.x_desc_proceso
            rObj.Materia = obj.x_desc_materia
            rObj.Delito = obj.delito_articulo
            return rObj
          })
        } else {
          this.itemsXls = this.dataConsulta.map(obj => {
            const rObj = {}
            rObj.Juzgado = obj.x_nom_instancia
            rObj.Estado = obj.nombre
            rObj.Cantidad = obj.cantidad
            return rObj
          })
        }
        this.exportarExcel()
      },
      exportarExcel () {
        const XLSX = require('xlsx')
        const data = XLSX.utils.json_to_sheet(this.itemsXls)
        const workbook = XLSX.utils.book_new()
        const filename = 'reporteCarga'
        XLSX.utils.book_append_sheet(workbook, data, filename)
        XLSX.writeFile(workbook, `${filename}.xlsx`)
      },
      consultarData () {
        if (this.$refs.form.validate()) {
          this.textoMensaje = 'CARGANDO INFORMACIÓN DEL SISTEMA'
          this.carga = true
          this.dataInicial = []
          this.dataConsulta = []
          const data = {
            n_instancia: this.juzgadoSeleccionado.n_instancia_id,
            n_dependencia: this.juzgadoSeleccionado.n_dependencia,
            n_funcion: this.juzgadoSeleccionado.N_FUNCION,
            anio: this.mesConsulta.split('-')[0],
            mes: this.mesConsulta.split('-')[1],
          }
          axios
            .post(
              `${window.__env.dataCfg.urlApiSIJWS}produccion/reporteIngresos`, data,
            )
            .then((response) => {
              if (response.data.status === 'shido') {
                this.tituloDialog = 'periodo ' + this.mesConsulta.split('-')[1] + '/' + this.mesConsulta.split('-')[0]
                this.carga = false
                response.data.data.forEach(element => {
                  if (element.n_unico) {
                    this.dataConsulta.push(element)
                  }
                })
                this.data2 = this.groupBy(this.dataConsulta, data => data.x_nom_instancia)
                this.dialogConsulta = true
                this.dataInicial = this.dataConsulta
                // this.generarAgrupacion(lodash.groupBy(this.dataInicial, item => `"${item.x_nom_instancia}}"`), 'juzgado')
                this.tipoData = 1
                this.filtroSeleccionado = ''
                this.juzgadoSeleccionado = ''
              } else {
                alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
                this.carga = false
              }
            })
            .catch((e) => {
              alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
              this.carga = false
                console.log(e); // eslint-disable-line
            })
        }
      },
      leerTodo () {
        this.dataConsulta = this.dataInicial
      },
      leerJuzgados () {
        axios
          .post(
            `${window.__env.dataCfg.urlApiSIJWS}instancias/instanciasActivas`,
          )
          .then((response) => {
            if (response.data.status === 'shido') {
              this.tituloDialog = 'Información de la consulta realizada del periodo ' + this.formatDate('DD/MM/YYYY', this.mesConsulta[0]) + ' y ' + this.formatDate('DD/MM/YYYY', this.mesConsulta[1])
              this.carga = false
              this.itemsJuzgado = response.data.data
            } else {
              alert('NO SE ENCONTRÓ LA INFORMACIÓN DE DE LOS JUZGADOS EN LAS FECHAS SELECCIONADAS')
              this.carga = false
            }
          })
          .catch((e) => {
            alert('ERROR EN LA CONSULTA A LA BASE DE DATOS, INTENTE NUEVAMENTE MÁS TARDE')
            this.carga = false
                console.log(e); // eslint-disable-line
          })
      },
      generarAgrupacion (arrayGroup, agruparPor) {
        this.tipoData = 2
        this.dataConsulta = []
        this.headers = [
          {
            text: 'Juzgado',
            value: 'x_nom_instancia',
            width: 230,
          },
          {
            text: 'Estado',
            value: 'nombre',
          },
          {
            text: 'Cantidad',
            value: 'cantidad',
          },
        ]
        const arrayFinal = Object.values(arrayGroup)
        for (var i = 0; i < arrayFinal.length; i++) {
          var sumatoria = arrayFinal[i].length
          if (agruparPor === 'estado') {
            this.dataConsulta.push({ x_nom_instancia: arrayFinal[i][0].x_nom_instancia, nombre: arrayFinal[i][0].var_des, cantidad: sumatoria })
          }
        }
      },
      cerrarSesion () {
        var logOut = confirm('¿Desea cerrar sesión?')
        if (logOut === true) {
          localStorage.removeItem('usuarioDataCSJLA')
          localStorage.removeItem('accesoDataJud')
          this.$router.push('/')
        }
      },
      groupBy (list, keyGetter) {
        const map = new Map()
        list.forEach((item) => {
          const key = keyGetter(item)
          const collection = map.get(key)
          if (!collection) {
            map.set(key, [item])
          } else {
            collection.push(item)
          }
        })
        return map
      },
      formatDate (template, date) {
        const specs = 'YYYY:MM:DD:HH:mm:ss'.split(':')
        date = new Date(
          date || Date.now() - new Date().getTimezoneOffset() * 6e4,
        )
        return date
          .toISOString()
          .split(/[-:.TZ]/)
          .reduce(
            (template, item, i) =>
            // eslint-disable-line
              template.split(specs[i]).join(item),
            template,
          )
      },
    },
  }
</script>
